import { Header } from '../../components/header/header';
import { Content } from './content/content';
import { Footer } from '../../components/footer/footer';
import { useAnchorScroller } from '../../components/common/anchor-scroller/anchor-scroller-hook';

export const MainPage = () => {
  useAnchorScroller();
  return (
    <>
      <Header />
      <Content />
      <Footer />
    </>
  );
};

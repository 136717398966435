import { Link } from 'react-router-dom';

import './footer.scss';

import mainLogo from '../../img/main-logo.svg';
import mobileLogo from './img/mobile-logo.svg';
import visa from './img/visa.svg';
import visaVerified from './img/visa-verified.svg';
import mastercard from './img/mc.svg';
import mastercardSecure from './img/mc-securecode.svg';
import pciDss from './img/pci-dss.svg';

export const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer-content'>
        <img className='footer-logo' src={ mainLogo } alt='' />

        <div className='footer-content-container'>
          <div className='footer-img-container'>
            <img className='footer-img' src={ visaVerified } alt='' />
            <img className='footer-img' src={ visa } alt='' />
            <img className='footer-img' src={ pciDss } alt='' />
            <img className='footer-img' src={ mastercard } style={ { padding: '3px 0' } } alt='' />
            <img className='footer-img' src={ mastercardSecure } alt='' />
          </div>

          <div className='footer-info'>
            <img className='mobile-footer-logo' alt='' src={ mobileLogo } />

            <div className='footer-info-container'>
              <div className='footer-links-block'>
                <Link to='/terms'>Terms of service</Link> | <Link to='/privacy'>Privacy policy</Link>
              </div>

              <div className='footer-info-block'>
                <span>© 2023 - Paytechlab Ltd. All rights reserved</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

/* eslint max-len: 0 */
export const AdditionalSection = () => (
  <div className='section additional-section'>
    <div className='container'>
      <h5>ADDITIONAL LEGAL TERMS</h5>
      <h2>Termination</h2>
      <li>Either party may terminate this Agreement immediately, by providing written notice detailing the reason for termination, in the event that (a) the other party materially breaches any of its obligations under the Agreement; (b) a substantial change in the terminating party’s business model or business climate makes this Agreement commercially unfeasible for the terminating party; (c) a petition has been filed or proceedings have commenced for the bankruptcy, dissolution, composition, or liquidation of the other party, whether voluntary or involuntary; or (d) any of the Corporations de-registers Paytechlab, Paytechlab’s Acquirer ceases to be a customer of any of the Corporations for any reason, or Acquirer fails to have a valid license with any of the Corporations to use the Marks. Paytechlab may at its own discretion or at the direction of the Acquirer or any of the Corporations, immediately terminate this Agreement for activity deemed to be potential or actual fraudulent or otherwise wrongful by Paytechlab, its Acquirer or any of the Corporations.</li>
      <li>Paytechlab reserves the right to immediately suspend Your access to and use of the Paytechlab Service or any part thereof if it determines in its sole discretion that You have breached any part of this Agreement, the terms of service provided on Paytechlab’s website, the terms of service provided by any participating third party payment provider, or if Paytechlab otherwise determines that suspension of access is necessary to prevent harm to the Paytechlab Service.</li>
      <li>In the event of any termination, Paytechlab may determine, at our own discretion, to suspend or cancel any transactions processed under the Paytechlab Services or to delay payment to You for up to six months, or longer if necessary, and may deduct from the payout any amounts necessary to satisfy any debts or obligations that result from Your account.</li>

      <h2>Indemnification</h2>
      <li>You agree to indemnify and hold Paytechlab, its payment processors, its providers, its licensors, the Corporations, and the respective subsidiaries, affiliates, agents, directors and employees of the same, harmless from and against any losses, costs, liabilities and expenses, including attorneys' fees, arising out of any claims relating to Your breach of this Agreement, any alleged violation or infringement for any copyright, trademark, trade name, or any other intellectual property, or any other claim about You, Your website or any of the goods or services advertised or delivered by You.</li>
      <li>The indemnified party reserves the right, at the indemnifying party's expense, to assume the exclusive defense and control of any matter for which the indemnifying party is required to indemnify the indemnified party and the indemnifying party agrees to cooperate with the indemnified party's defense of such claims.</li>

      <h2>Disclaimer of Warranty</h2>
      <li>Paytechlab SERVICES, AND ANY OTHER MATERIALS OR SERVICES PROVIDED IN CONNECTION THEREWITH, ARE PROVIDED “AS IS” AND “AS AVAILABLE,” AND YOU RECEIVE AND USE THOSE SERVICES AT YOUR? ITS OWN RISK. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICES ARE PROVIDED WITHOUT WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, Paytechlab, ITS PAYMENT PROCESSORS, ITS PROVIDERS, ITS LICENSORS (AND THEIR RESPECTIVE SUBSIDIARIES, AFFILIATES, AGENTS, DIRECTORS, AND EMPLOYEES) DO NOT WARRANT THAT THE SERVICES WILL BE ERROR FREE, UNINTERRUPTED, OR SECURE; THAT ANY DEFECTS OR ERRORS WILL BE CORRECTED; OR THAT THE SERVICES ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. ANY CONTENT OR DATA DELIVERED ON YOUR REQUEST OR OBTAINED THROUGH THE USE OF THE SERVICES IS OBTAINED AT ITS OWN RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO ITS PROPERTY OR LOSS OF DATA THAT MAY RESULT. Paytechlab DOES NOT WARRANT THE RESULTS OF USE OF THE Paytechlab SERVICE, INCLUDING, WITHOUT LIMITATION, THAT YOU WILL EARN ANY PARTICULAR AMOUNTS (OR ANY AMOUNTS) HEREUNDER.</li>
      <li>Paytechlab DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES. Paytechlab WILL NOT BE A PARTY TO OR IN ANY WAY MONITOR ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES, BEYOND WHAT IS EXPRESSLY STATED BY Paytechlab.</li>

      <h2>Limitation of Liability and Damages</h2>
      <li>Paytechlab shall not be liable to You or any other party if Paytechlab is delayed or unable to fulfill any of its obligations in this Agreement due to an event beyond the reasonable control of Paytechlab, including but not limited to acts of God, strikes, labor disputes, war, terrorism, riots, acts of civil or military authority, economic instability, power outages, fire, flood, theft, equipment breakdowns, hacking attacks, internet connection unavailability, internal mechanical or systems failures. Paytechlab shall also not be liable in any case for any transaction where the payment instructions received contain incorrect or improperly formatted information or any suspension or refusal to accept a payment that Paytechlab reasonably believes to be made fraudulently or without proper authorization.</li>
      <li>UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, WILL Paytechlab OR ITS AFFILIATES BE LIABLE FOR ANY SPECIAL, INDIRECT, INCIDENTAL, CONSEQUENTIAL, PUNITIVE, RELIANCE, OR EXEMPLARY DAMAGES THAT RESULT FROM THIS AGREEMENT, EVEN IF Paytechlab OR A Paytechlab AUTHORIZED REPRESENTATIVE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. EXCEPT IN THE CASES OF BREACHES OF THIS SECTION, IN NO EVENT WILL Paytechlab'S OR ITS AFFILIATES' TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION ARISING OUT OF OR RELATING TO THIS AGREEMENT (WHETHER IN CONTRACT OR TORT, INCLUDING NEGLIGENCE, WARRANTY, OR OTHERWISE) EXCEED THE AMOUNTS PAID OR PAYABLE TO YOU FOR THE TRANSACTION TO WHICH THE CLAIM RELATES.</li>
      <li>UNDER NO CIRCUMSTANCES SHALL Paytechlab OR ITS AFFILIATES BE RESPONSIBLE OR HELD LIABLE FOR ANY TRANSACTIONS OR PAYMENTS THAT ARE BLOCKED BY THIRD PARTIES, INCLUDING BANKS, FINANCIAL INSTITUTIONS, OR ANY GOVERNMENT BODY. THIS DISCLAIMER OF LIABILITY INCLUDES ANY RESTRICTION BASED ON APPLICABLE INTERNATIONAL, NATIONAL, OR LOCAL LAWS, RULES AND REGULATIONS, THE SPECIALLY DESIGNATED NATIONALS LIST PUBLISHED BY THE OFFICE OF FOREIGN ASSETS CONTROL, ANY INTERNAL BANK “BLACK LISTS,” OR ANY OTHER SIMILARLY RESTRICTIVE THIRD PARTY MEASURES.</li>

      <h2>Ownership</h2>
      <li>You acknowledge that Paytechlab will provide payment options using its Paytechlab Service for display on the Projects pursuant to this Agreement, and You will use commercially reasonable efforts to assist Paytechlab in implementing such technology. You agree that it will use any data (including any usage data and compilations thereof), information or software provided by Paytechlab to You only for the purpose of providing content for Paytechlab on the Site as set forth in this Agreement. Except as expressly described in Section “Licences”, Paytechlab does not grant to You any license, express or implied, to the intellectual property of Paytechlab or its licensors. Paytechlab will own and retain all rights, title, and interest in and to the Paytechlab Service (except for any licensed content and third-party content included therein), including all data (including any usage data and compilations thereof), information and software related thereto. You acknowledge that the software, information and data related to the Paytechlab Service (including, without limitation, any usage data or compilations thereof) are protected by Paytechlab copyrights and may contain trade secrets or other intellectual property owned by Paytechlab. You agree not to copy, alter, modify or create derivative works of the Paytechlab Service or any such data, information or software or otherwise use the Paytechlab Service or any such data, information or software in any way that violates the use restrictions contained in this Agreement.</li>

      <h2>Term</h2>
      <li>The term of this Agreement will be one (1) year from the Effective Date, which shall be the date that Your Paytechlab Account is created. The agreement will automatically renew itself for another year at the expiration date unless a written notice is given to the other party to terminate the agreement.</li>

      <h2>Miscellaneous</h2>
      <li>This Agreement, and any rights and licenses granted hereunder, may not be transferred or assigned by You to any party (the “Assignee”) without the prior written consent of Paytechlab, which shall only be granted if the Assignee meets Paytechlab’s underwriting and compliance requirements. Any purported assignment without such consent shall be void. This clause shall in no way restrict Paytechlab’s right to transfer or assign this Agreement.</li>
      <li>Paytechlab and You are independent contractors, and neither Paytechlab nor You are an agent, representative or partner of the other.</li>
      <li>This Agreement sets forth the entire agreement between Paytechlab and You, and supersedes any and all prior agreements (whether written or oral) with respect to the subject matter set forth herein.</li>
      <li>The official language of this Agreement is the English language. Any conflict or ambiguity between the English version of this Agreement and any other version of this Agreement in a different language shall be resolved based on the English version.</li>
      <li>Any dispute hereunder will be privately negotiated in good faith between the parties within forty-five (45) calendar days commencing upon written notice from one party to the other. If the parties fail to privately resolve any dispute, the parties shall submit to arbitration performed by a mutually agreed upon arbitration provider in London, United Kingdom.</li>
      <li>This Agreement shall be governed by and construed in accordance with the laws of United Kingdom. You agree that any action at law or in equity arising out of or relating to this Agreement will be filed only in the United Kingdom, and You hereby consents and submits to the personal and exclusive jurisdiction of such courts for the purposes of litigating any such action.</li>
      <li>
        This Agreement may be amended only in writing and executed by a duly authorized representative of each party. However, Paytechlab may make changes by providing written notice to You under the following procedure:
        <ol className='lettered-list'>
          <li>Paytechlab shall provide You with written notice of any proposed changes to the Agreement ("Change Notice");</li>
          <li>the Change Notice may be given to You in written form through the Paytechlab Admin Area, by email to an address that is registered with Your Account, Your current or last known address, or to Your registered office;</li>
          <li>the proposed changes shall come into force automatically two (2) weeks after the date of the Change Notice, unless You give prior written notice to Paytechlab that it objects to the proposed changes and wishes to immediately terminate the Agreement before the Change Notice takes effect ("Objection Notice");</li>
          <li>Paytechlab may stipulate a longer period for the coming into effect of any change in a Change Notice; and</li>
          <li>if Paytechlab receives no Objection Notice within the stipulated time frame, You are deemed to have accepted the proposed changes.</li>
        </ol>
      </li>
      <li>
        Unless otherwise designated in this Agreement, any notice or consent under this Agreement will be in writing to Your address specified in Your Paytechlab Admin Account, by nationally recognized express delivery service or to the email address provided for each party. The notices shall be deemed to have been given upon:
        <ol className='lettered-list'>
          <li>the date actually delivered in person;</li>
          <li>the first business day after the date sent by email or overnight courier;</li>
        </ol>
      </li>
      <li>The waiver of any breach or default of this Agreement will not constitute a waiver of any subsequent breach or default, and will not act to amend or negate the rights of the waiving party.</li>
      <li>If any provision contained in this Agreement is determined to be invalid, illegal, or unenforceable in any respect under any applicable law, then such provision will be severed and replaced with a new provision that most closely reflects the original intention of the parties, and the remaining provisions of this Agreement will remain in full force and effect.</li>
    </div>
  </div>
);

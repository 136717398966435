import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Slider, { Settings } from 'react-slick';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';

import { ReactComponent as Arrow } from './img/arrow.svg';
import { Slide } from './slide';
import { Section } from '../../../../components/common/section';
import { SectionAnchor } from '../../../../models/section-anchor';
import './services-section.scss';
import { useWindowDimensions } from '../../../../helpers/use-window-dimentions';

const settings: Settings = {
  dots: false,
  swipe: true,
  arrows: false,
  infinite: true,
  speed: 300,
  slidesToShow: 1,
  centerMode: true,
  variableWidth: true,
  slidesToScroll: 1,
  adaptiveHeight: true,
};

const SLIDE_CHANGE_TIMEOUT = 5000;

export const ServicesSection = () => {
  const sliderRef = useRef<Slider | any>();

  const [percent, setPercent] = useState(0);

  const startTime = useRef(+new Date());
  const timerRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  const clearTimer = useCallback(() => {
    startTime.current = +new Date();
    setPercent(0);
  }, []);

  const loop = useCallback(() => {
    timerRef.current = setTimeout(() => {
      const timeDiff = +new Date() - startTime.current;
      const newPercent = (timeDiff / SLIDE_CHANGE_TIMEOUT) * 100;
      if (newPercent > 100) {
        startTime.current = +new Date();
        sliderRef.current?.slickNext();
        clearTimer();
      } else {
        setPercent((timeDiff / SLIDE_CHANGE_TIMEOUT) * 100);
      }
      loop();
    }, 100);
  }, [clearTimer]);

  useEffect(() => {
    loop();
  }, [loop]);

  const { windowWidth } = useWindowDimensions();

  const isMobile = useMemo(() => (windowWidth || 0) < 600, [windowWidth]);

  const arrows = useMemo(() => (
    <div className='arrows-container'>
      <div className='arrows'>
        <div className='arrow-container prev'>
          <Arrow
            className='arrow-img prev'
            onClick={ () => {
              sliderRef.current?.slickPrev();
              clearTimer();
            } }
          />
        </div>
        <div className='arrow-container next'>
          <CircularProgressbar
            className='progress'
            strokeWidth={ 6 }
            value={ percent }
            styles={ buildStyles({
              pathColor: '#3DA2FF',
              pathTransitionDuration: 0.1,
            }) }
          />
          <Arrow
            className='arrow-img next'
            onClick={ () => {
              sliderRef.current?.slickNext();
              clearTimer();
            } }
          />
        </div>
      </div>
    </div>
  ), [clearTimer, percent]);

  return (
    <Section
      anchor={ SectionAnchor.Services }
      offset={ -90 }
      className='services-section'
    >
      <div className='container'>
        <div className='background'>
          <h5 className='section-header'>Services</h5>
          <h2 className='section-subheader'>Product Development services</h2>
          <p className='section-text'>
            Transform your ideas to life via software product, enhance existing product quality, reduce
            time-to-market, and decrease development costs with our turn-key software engineering
            services
          </p>
        </div>
      </div>
      { !isMobile && arrows }
      <div className='slider'>
        <Slider { ...settings } ref={ sliderRef } onSwipe={ clearTimer }>
          <div className='slide-item'>
            <Slide
              header='Strategy & Concept'
              text='
                Together with the Customer our product
                team determine the goals of the project,
                perform market analysis, competitor
                analysis, user behavior analysis, develop a
                product concept and strategy'
            />
          </div>
          <div className='slide-item'>
            <Slide
              header='Analysis & Requirements'
              text='
                An in-depth analysis of business
                requirements is carried out, as well as an
                additional analysis of the market,
                competitors, similar products, and user
                behavior. UI/UX prototypes and technical
                requirements of the future product and
                features are created'
            />
          </div>
          <div className='slide-item'>
            <Slide
              header='Development & Testing'
              text='
               The technical team analyzes the
               requirements, interprets them into technical
               tasks, distributes them among the executive
               team. After successful implementation, all
               tasks are tested and verified. The best
               modern practices are used in software
               development and testing'
            />
          </div>
          <div className='slide-item'>
            <Slide
              header='Support & Maintenance'
              text='
               We provide technical and customer support
               for our products and solutions. Our support
               team provides service, system and
               application support and maintenance
               services to ensure your business-critical
               systems are fast and available to use 24/7'
            />
          </div>
        </Slider>
        { isMobile && arrows }
      </div>
    </Section>
  );
};

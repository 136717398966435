import { ReactNode } from 'react';
import './common-page.scss';

import { Header } from '../../header/header';
import { Footer } from '../../footer/footer';

export type CommonPageProps = {
  pageTitle: string;
  className?: string;
  children: ReactNode;
};

export const CommonPage = (props: CommonPageProps) => {
  return (
    <div className={ `common-page ${ props.className }` }>
      <div className='header-block'>
        <Header />
      </div>
      <div className='main-header-block'>
        <h1>{ props.pageTitle }</h1>
      </div>

      { props.children }
      <Footer />
    </div>
  );
};

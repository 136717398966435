import { MouseEvent, ReactNode } from 'react';

import { Link, useLocation } from 'react-router-dom';

import { AnchorScrollerMode, scrollToHash } from './anchor-scroller-helpers';
import { SectionAnchor } from '../../../models/section-anchor';

interface IAnchorScrollerProps {
  anchor: SectionAnchor;
  mode?: AnchorScrollerMode;
  offset?: number;
  className?: string;
  children: ReactNode;
}

export const AnchorLink = (props: IAnchorScrollerProps) => {
  const location = useLocation();

  const handleClick = (event: MouseEvent) => {
    if (location.pathname !== '/') {
      return;
    }
    event.preventDefault();
    scrollToHash(props.anchor, props.mode, props.offset);
  };

  return (
    <Link
      to={ `/#${ props.anchor }` }
      className={ props.className || '' }
      onClick={ handleClick }
    >
      { props.children }
    </Link>
  );
};

import React from 'react';

import './app.scss';
import { Navigate, Route, Routes } from 'react-router-dom';

import { MainPage } from './routes/main/main-page';
import { CookiesWarning } from './components/common/cookies-warning/cookies-warning';
import { TermsPage } from './routes/terms/terms-page';
import { PrivacyPage } from './routes/privacy/privacy-page';

function App() {
  return (
    <div className='app'>
      <Routes>
        <Route index element={ <MainPage /> } />
        <Route path='terms' element={ <TermsPage /> } />
        <Route path='privacy' element={ <PrivacyPage /> } />
        <Route path='*' element={ <Navigate to='/' /> } />
      </Routes>
      <CookiesWarning />
    </div>
  );
}

export default App;

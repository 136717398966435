import { useCallback, useState } from 'react';

import { Link } from 'react-router-dom';

import { Button } from '../../shared/button/button';

import './cookies-warning.scss';

export const CookiesWarning = () => {
  const [cookiesAccepted, setCookiesAccepted] = useState(!!localStorage.getItem('cookiesAccepted'));

  const handleClick = useCallback(() => {
    setCookiesAccepted(true);
    localStorage.setItem('cookiesAccepted', 'true');
  }, []);

  if (cookiesAccepted) {
    return <></>;
  }

  return (
    <div className='cookies-block'>
      <div className='text-container'>
        <p>
          Paytechlab.com site uses cookies to offer you a better browsing experience.
          By continuing to use the site, you agree with our <Link to='/privacy'>Privacy Policy</Link>
        </p>
      </div>
      <div className='button-container'>
        <Button onClick={ handleClick } outline>
          I agree
        </Button>
      </div>
    </div>
  );
};

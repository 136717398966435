import { ReactNode } from 'react';

import { SectionAnchor } from '../../models/section-anchor';

interface SectionProps {
  anchor: SectionAnchor;
  children: ReactNode;
  className?: string;
  offset?: number;
}

export const Section = (props: SectionProps) => {
  return (
    <section
      className={ props.className || '' }
      data-section={ props.anchor }
      data-anchor-offset={ props.offset || 0 }
    >
      { props.children }
    </section>
  );
};

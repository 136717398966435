import './content.scss';
import { TopSection } from './top-section/top-section';
import { SolutionsSection } from './solutions/solutions-section';
import { ServicesSection } from './services/services-section';
import { BenefitsSection } from './benefits/benefits-section';
import { CompanySection } from './company/company-section';
import { ContactFormSection } from './contact-form/contact-form-section';

export const Content = () => {
  return (
    <main className='content'>
      <TopSection />
      <SolutionsSection />
      <ServicesSection />
      <BenefitsSection />
      <CompanySection />
      <ContactFormSection />
    </main>
  );
};

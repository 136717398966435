import { ReactComponent as ArrowDownImg } from '../../../../../img/arrow-down.svg';

export type BenefitsGridItemProps = {
  image: string;
  header: string;
  text: string;
  isActive: boolean;
  setItemActive: () => void;
};

export const BenefitsGridItem = (props: BenefitsGridItemProps) => {
  return (
    <div className={ `benefits-grid-item ${ props.isActive ? 'active' : '' }` }>
      <div className='head' onClick={ props.setItemActive }>
        <img className='benefits-grid-image' src={ props.image } alt='efficiency' />
        <h4 className='benefits-grid-header'>{ props.header }</h4>
        <ArrowDownImg className='arrow' />
      </div>
      <p className='benefits-grid-text'>
        { props.text }
      </p>
    </div>
  );
};

/* eslint-disable max-len */
import { useEffect } from 'react';

import { CommonPage } from '../../components/common/common-page/common-page';
import './privacy-page.scss';

export const PrivacyPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <CommonPage className='privacy-page' pageTitle='Privacy Policy'>
      <section className='section'>
        <div className='container'>
          <p>Last updated November 01, 2022</p>
          <p>We have written this Privacy Policy to show how we handle your personal information. It explains what data we collect and what we do with it. It also describes our reasons and legal grounds for collecting, storing, processing and sharing your personal data.</p>
          <p>We encourage you to read this Privacy Policy to understand how we protect the privacy of your data.</p>

          <h2>Definitions</h2>

          <ul className='disk-list'>
            <li>
              <b>Account.</b> A Paytechlab’s account.
            </li>
            <li>
              <b>Personal data.</b> Any information that can be used to identify an individual, such as your name, birthdate, phone number, home address, email address, billing data, national identification number, IP address, etc.
            </li>
            <li>
              <b>Process.</b> Any procedures we use to handle the personal data. These procedures are mostly automatic: acquisition, recording, aggregation, storing, structuring, modification, retrieving, disclosing, and disposing of Personal Data.
            </li>
            <li>
              <b>Services.</b> All services, features, technologies, products, and content provided by Paytechlab via this website and our applications.
            </li>
            <li>
              <b>Website.</b> <a href='//paytechlab.com'>paytechlab.com</a>, our mobile apps, official pages on social media, and all other platforms by means of which we render our services online.
            </li>
          </ul>

          <p>We cannot identify you when browsing our website unless you willingly provide us your personal data. In this case, your data will be processed according to the relevant laws and regulations as well as our Privacy Policy.</p>

          <h2>When we collect your personal data?</h2>
          <p>When you visit our website, create an account or use our services, Paytechlab might collect your personal data to improve your experience or make it possible to provide the services. This data includes the information that you choose to submit:</p>
          <ul>
            <li>when you create an Paytechlab account or use our services.</li>
            <li>when you reach out for our support (as a way to get back to you).</li>
            <li>when you subscribe for our marketing materials.</li>
          </ul>

          <h2>What kind of data we collect?</h2>
          <p>This information may include your name, email address, phone number, IP address, and other device identifiers. We may also track your behavior on the website and collect any data that you voluntarily submit to Paytechlab.</p>

          <h2>Cookies</h2>
          <p>Paytechlab and our partners may use technologies known as Cookies to help us identify you and track your activity on our website. Cookies help us to customize your experience with the website, improve our advertising and gauge its effectiveness, as well as boost our security and fight against fraud.</p>
          <p>To access some of the Paytechlab’s features and services, you need to accept Cookies. Opting out of Cookies will limit the use of our services or make it impossible.</p>

          <h2>What is the purpose, legal grounds and the duration of the data processing?</h2>
          <p>We only process your data with a specific intention and for a limited duration. The process is based on the law. Paytechlab will process your data only for the purposes detailed in Privacy Policy.</p>
          <ul>
            <li>So that you receive our marketing materials or those of our partners.</li>
            <li>So that we meet our legal obligations and payment card industry requirements.</li>
            <li>So that we provide you our services, get back to you when you contact us for more information, defend your and other users’ rights, protect and improve our services, and other legitimate business interests of Paytechlab.</li>
          </ul>
          <p>So that we meet our legal obligations and payment card industry requirements.</p>
          <p>So that we provide you our services, get back to you when you contact us for more information, defend your and other users’ rights, protect and improve our services, and other legitimate business interests of Paytechlab.</p>

          <h2>Whom we share your data with?</h2>
          <p>To provide our services, we may share your data with other parties including our business partner and other parties if required by the law or our legitimate interests. Paytechlab may also share de-identified aggregate data with other companies and individuals. We might share your data in other ways only upon receiving your explicit consent.</p>

          <h2>International transfer of data</h2>
          <p>To provide our services, we use a global infrastructure of third-party servers and information technology. Together with the infrastructure providers, we can process your personal information in countries from outside the EU. In such a case, the data would be transferred under the standard data protection clauses adopted by the European Commission and outlined in GDPR articles 45-46.</p>
          <p>By using this website or our services, you consent to your personal information being sent to other countries even if they have different regulations governing data protection. When sent to other countries, your data is protected according to our Privacy Policy.</p>

          <h2>Changes</h2>
          <p>Occasionally we may revise our Privacy Policy. The changes would come into effect from the published effective date. We will inform you of any substantial changes to the Privacy Policy beforehand.</p>
          <p>You can contact us if you have any questions about our privacy practices that are not discussed on this page by means of email: <a href='mailto:support@paytechlab.com'>support@paytechlab.com</a></p>
        </div>
      </section>
    </CommonPage>
  );
};

import './terms-page.scss';
import { useEffect } from 'react';

import { GeneralItemsSection } from './sections/general-items-section';
import { TechnologySection } from './sections/technology-section';
import { PaymentTermsSection } from './sections/payment-terms-section';
import { DataProtectionSection } from './sections/data-protection-section';
import { AdditionalSection } from './sections/additional-section';
import { CommonPage } from '../../components/common/common-page/common-page';

export const TermsPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  return (
    <CommonPage className='terms-page' pageTitle='Terms of Service'>
      <ol className='numbered-list'>
        <GeneralItemsSection />
        <TechnologySection />
        <PaymentTermsSection />
        <DataProtectionSection />
        <AdditionalSection />
      </ol>
    </CommonPage>
  );
};

import { ReactNode, useMemo } from 'react';

import './button.scss';

export type ButtonProps = {
  children: ReactNode;
  gradient?: boolean;
  outline?: boolean;
  tabIndex?: number;
  type?: 'submit';
  className?: string;
  onClick?: () => any;
};

export const Button = (props: ButtonProps) => {
  const classNames = useMemo(() => {
    let classes = `button ${ props.className || '' }`;
    if (props.gradient) {
      classes += ' gradient';
    }
    if (props.outline) {
      classes += ' outline';
    }

    return classes;
  }, [props.className, props.gradient, props.outline]);

  return (
    <button
      className={ classNames }
      tabIndex={ props.tabIndex }
      type={ props.type || 'button' }
      onClick={ props.onClick }
    >
      { props.children }
    </button>
  );
};

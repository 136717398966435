import { BenefitsGrid } from './benefits-grid/benefits-grid';
import { Section } from '../../../../components/common/section';
import { SectionAnchor } from '../../../../models/section-anchor';

import './benefits-section.scss';

export const BenefitsSection = () => {
  return (
    <Section
      anchor={ SectionAnchor.Benefits }
      offset={ -100 }
      className='benefits-section'
    >
      <div className='container'>
        <h5 className='section-header'>Benefits</h5>
        <h2 className='section-subheader'>Boost your business right now</h2>
        <p className='section-text'>
          Keep your business competitive with our value-added payment solutions.
          Optimize your workflows, enhanced customer experience and increase sales
        </p>

        <BenefitsGrid />
      </div>
    </Section>
  );
};

/* eslint-disable max-len */
export const PaymentTermsSection = () => (
  <div className='section payment-terms-section'>
    <div className='container'>
      <h5>PAYMENT TERMS</h5>
      <h2>Payment terms</h2>
      <li>For acquiring services You request a complete blending of Fees for payment card processing for all merchant services charges (MSC) for all payment card brands and categories irrespective of the underlying differences in interchange fees.</li>
      <li>Your use of the Paytechlab Services is subject to additional terms that apply between You and one or more of Paytechlab, a Paytechlab affiliate, or a Acquiring Services Provider. By using the Paytechlab Services, You agree to applicable Payment Terms as set out.</li>
      <li>A clearing account through which funds are settled will be maintained by a Payment Services Provider, and transfers to You from this account will be a full and final payment by the Payment Services Provider to You.</li>
      <li>You shall be solely responsible for determining which taxes, if any, apply to the payments received, and to report and remit the correct tax to the appropriate tax authority. Paytechlab is not obligated to determine whether taxes apply, and is not responsible to collect, report, or remit any taxes arising from any transaction. You shall be solely responsible for the timely payment of all applicable federal, state or local taxes, including any VAT, sales, use, excise or transfer taxes, and other taxes associated with payments to You under this Agreement, except for taxes assessed on Paytechlab’s net income and local taxes that are already paid by Paytechlab or by third party processors. You shall indemnify Paytechlab against any losses, costs, liabilities, expenses, including attorneys’ fees arising out of Your failure to fully comply with this section.</li>

      <h2>Credit Card Acceptance</h2>
      <li>When accepting payment card payments, You must comply with all Network Rules applicable to merchants, including the Network Rules provided by Visa, MasterCard, and American Express. These Network Rules state that You may only accept payment using payment cards for bona fide legal commercial transactions, may only use payment network trademarks or service marks consistent with the Network Rules, and may not discriminate by card type or charge surcharges for acceptance of payment cards. The payment card networks may amend the Network Rules at any time without notice to You, and Paytechlab reserves the right to change the Paytechlab Services for payment card processing at any time to comply with the Network Rules. We may share the information You provide to us that we use to identify the nature of the products or services with Services Providers, including assigning Your business activities to a particular payment network merchant category code (MCC). Customers typically raise payment card network Disputes (i.e. chargebacks) when a merchant fails to provide the product or service to the Customer, or where the payment card account holder did not authorise the Charge. High chargeback rates (typically those exceeding 1%) may result in Your inability to use the Paytechlab Services.</li>
      <li>When You accept payment card Transactions, Network Rules specifically prohibit You from (I) providing cash refunds for a Charge on a credit card, unless required by Laws, (II) accepting cash, its equivalent, or any other item of value for a Refund, (III) acting as a payment intermediary or aggregator, or otherwise reselling Paytechlab Services on behalf of others, (IV) submitting what You believe or know to be a fraudulent Charge, or (V) using Paytechlab Services in a manner that is an abuse of Services Providers’ networks or a violation of Network Rules. If You misuse the Paytechlab Services for payment card transactions or engage in activity the payment card networks identify as damaging to their brand, or if we are required to do so by Network Rules, we may submit information about You, Representatives, Principals, beneficial owners and other individuals associated with Your Paytechlab Account to the MATCH terminated merchant listing maintained by MasterCard and accessed and updated by Visa and American Express, or to the Consortium Merchant Negative File maintained by Discover. Addition to one of these lists may result in Your inability to accept payments from payment cards. You understand and consent to our sharing this information and to the listing itself, and You will fully reimburse us for any losses we incur from third-party claims, and You waive Your rights to bring any direct claims against us that result from such reporting.</li>

      <h2>Refunds</h2>
      <li>Paytechlab may issue refunds on Your behalf in its sole discretion in response to end-user inquiries or if Paytechlab determines that the payment is fraudulent, was submitted by a non-verified user, or if it is an otherwise illegal transaction. If Paytechlab fails to receive the appropriate confirmation from You or if an end user inquires about any transaction, Paytechlab will contact You for more information and/or to confirm whether the goods or services were actually delivered to the end user. All notifications will be sent to the email address that You provide to Paytechlab. If You do not adequately respond to Paytechlab within three (3) days of Paytechlab’s request, Paytechlab may issue a refund to the end user without further notice.</li>

      <h2>Fraud and Chargebacks</h2>
      <li>Paytechlab will not be obligated to pay for any fraudulent actions generated by any person, bot, automated program, or similar device on any Paytechlab product, where it is not expressly stated that the fraud is covered, in connection with any payment collected by Paytechlab, as reasonably determined by Paytechlab. You are solely liable to Paytechlab for the full amount of all chargebacks plus associated fees, fines, expenses or penalties (including those assessed by the Corporations or any payment provider). Paytechlab may recover these amounts by debiting Your Rolling Reserve account or setting off any amount owed to You. If Paytechlab determines in its reasonable discretion that a chargeback is likely for any transaction, Paytechlab may withhold the amount of a transaction until the expiration of the period during which the end user may dispute the transaction, the chargeback is processed, or Paytechlab determines that a chargeback will not occur. Additional restrictions, fees, penalties, or fines may apply if Paytechlab, the Corporations, or any payment provider determines that You are incurring excessive chargebacks. Excessive chargebacks may result in changes toholds on payouts to You, suspension of the Paytechlab Services, or termination of this Agreement.</li>
      <li>You authorize Paytechlab to contest any chargebacks (or any subsequent appeals thereof) against third parties on behalf of You, if Paytechlab chooses to do so in its sole discretion. This provision applies so long as Paytechlab has an interest in the chargeback even if any of the following events occurs to You including but not limited to: the filing of or commencement of bankruptcy proceeding or insolvency whether voluntary or involuntary, the dissolution of its entity, or the liquidation of Your assets. You agree to cooperate and provide all information that Paytechlab requests from You for the purposes of investigating and/or contesting a chargeback. Paytechlab will send such requests to the email address that You provide to Paytechlab and You must provide a full response to the request within fourteen (14) days, unless another timeframe is specified in the request. If You do not meet these requirements, You shall nonetheless be responsible for all costs or losses that Paytechlab incurs as a result of Your failure to comply with this section, in addition to the chargeback amounts plus associated fees, fines, expenses or penalties (including those assessed by the Corporations or any payment provider). Paytechlab assumes no liability for the resolution of any chargeback case.</li>
      <li>You acknowledge and agree that notwithstanding the termination of this Agreement for any reason, Paytechlab shall remain entitled to contest and recover chargebacks from You (and, where if relevant, from any party who has provided Paytechlab with a guarantee or security relating to Your obligations under this Agreement) that occur in relation to transactions effected during the term of this Agreement.</li>
    </div>
  </div>
);

import { SectionAnchor } from '../../models/section-anchor';
import { AnchorLink } from '../common/anchor-scroller/anchor-link';
import headerLogo from '../../img/main-logo.svg';

import './header.scss';

export const Header = () => {
  return (
    <header className='header'>
      <AnchorLink anchor={ SectionAnchor.Index } className='header-logo-container'>
        <img src={ headerLogo } alt='logo' className='header-logo' />
      </AnchorLink>
      <div className='header-content'>
        <AnchorLink className='header-link' anchor={ SectionAnchor.Solutions }>Solutions</AnchorLink>
        <AnchorLink className='header-link' anchor={ SectionAnchor.Services }>Services</AnchorLink>
        <AnchorLink className='header-link' anchor={ SectionAnchor.Benefits }>Benefits</AnchorLink>
        <AnchorLink className='header-link' anchor={ SectionAnchor.Company }>Company</AnchorLink>
        <AnchorLink className='header-link' anchor={ SectionAnchor.Contacts }>Contacts</AnchorLink>
        <a href='https://u24.gov.ua' className='header-link' rel='noreferrer noopener' target='_blank'>
          #standwithUkraine
        </a>
      </div>
    </header>
  );
};

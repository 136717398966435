import './slide.scss';

export type SlideProps = {
  header: string;
  text: string;
};

export const Slide = ({ header, text }: SlideProps) => {
  return (
    <div className='slide'>
      <div className='slide-content'>
        <h2 className='slide-header'>
          { header }
        </h2>
        <div className='text'>
          { text }
        </div>
      </div>
    </div>
  );
};

import './company-section.scss';
import { Section } from '../../../../components/common/section';
import { SectionAnchor } from '../../../../models/section-anchor';
import { ReactComponent as BgLogoMobile } from './bg-logo-mobile.svg';
import { ReactComponent as BgLogoDesktop } from './bg-logo-desktop.svg';

export const CompanySection = () => {
  return (
    <Section anchor={ SectionAnchor.Company } offset={ -100 } className='company-section'>
      <div className='container'>
        <BgLogoMobile className='bo-logo-mobile' />
        <BgLogoDesktop className='bo-logo-desktop' />
        <h5 className='section-header'>Company</h5>
        <h2 className='section-subheader'>Payment Technologies Lab is a full-cycle fintech development company</h2>
        <div className='texts'>
          <p className='section-text'>
            {/* eslint-disable-next-line max-len */}
            We have a clear vision of how Fintech solutions should be developed to move the industry to the next step and satisfy the needs of modern businesses
          </p>
          <p className='section-text'>
            {/* eslint-disable-next-line max-len */}
            Paytechlab wants to ensure the availability of online payments all over the globe and help to increase the business efficiency for those, who use our solutions. Join the future of payments, join Paytechlab
          </p>
        </div>
      </div>
    </Section>
  );
};

import { useState } from 'react';

import efficiencyImg from '../icons/efficiency.svg';
import customizationImg from '../icons/customization.svg';
import reliabilityImg from '../icons/reliability.svg';
import expertiseImg from '../icons/expertise.svg';
import growthImg from '../icons/growth.svg';
import exclusivenessImg from '../icons/exclusiveness.svg';
import { BenefitsGridItem } from './benefits-grid-item';

import './benefits-grid.scss';

type ConfigItem = {
  image: string;
  header: string;
  text: string;
};

const config: ConfigItem[] = [
  {
    image: efficiencyImg,
    header: 'Efficiency',
    // eslint-disable-next-line max-len
    text: 'Paytechlab products and services are made with a high-efficiency principle to ensure the best performance of your payments',
  },
  {
    image: customizationImg,
    header: 'Customization',
    // eslint-disable-next-line max-len
    text: 'Our solutions support customization to guarantee that you will match your customers and reach the goals you have',
  },
  {
    image: reliabilityImg,
    header: 'Reliability',
    // eslint-disable-next-line max-len
    text: 'Paytechlab has PCI DSS compliance as well as internal security mechanisms to provide high uptime and safety of your data',
  },
  {
    image: expertiseImg,
    header: 'Expertise',
    // eslint-disable-next-line max-len
    text: 'Our experts are ready to provide consultations on how to tune Paytechlab solutions to receive the maximum profit for your business',
  },
  {
    image: growthImg,
    header: 'Growth',
    // eslint-disable-next-line max-len
    text: 'Paytechlab products are developed to allow you easily expand to new markets, regions and countries and grow exponentially',
  },
  {
    image: exclusivenessImg,
    header: 'Exclusiveness',
    // eslint-disable-next-line max-len
    text: 'Reach the leading position on the market by using the exclusive solutions, services and features made by Paytechlab',
  },
];

export const BenefitsGrid = () => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  return (
    <div className='benefits-grid'>
      { config.map((item, i) => (
        <BenefitsGridItem
          key={ i }
          { ...item }
          isActive={ activeItemIndex === i }
          setItemActive={ () => setActiveItemIndex((val) => (val === i ? -1 : i)) }
        />
      )) }
    </div>
  );
};

import { Button } from '../../../../components/shared/button/button';
import { SectionAnchor } from '../../../../models/section-anchor';
import { AnchorLink } from '../../../../components/common/anchor-scroller/anchor-link';
import { Section } from '../../../../components/common/section';

import './top-section.scss';

export const TopSection = () => (
  <Section className='top-section' anchor={ SectionAnchor.Index }>
    <div className='top-section-background' />
    <div className='container'>
      <h1>Boost your revenue globally with advanced fintech software</h1>
      <p>
        Expand your presence, increase conversion,
        stay compliant through our easy and cost-efficient payment solutions
      </p>
      <AnchorLink anchor={ SectionAnchor.Contacts } className='link-holder'>
        <Button tabIndex={ -1 }>
          Contact us
        </Button>
      </AnchorLink>
    </div>
  </Section>
);

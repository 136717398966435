/* eslint-disable max-len */
export const TechnologySection = () => (
  <div className='section technology-section'>
    <div className='container'>
      <h5>Paytechlab’S TECHNOLOGY</h5>
      <h2>License</h2>
      <li>Paytechlab hereby grants to You a non-exclusive, non-transferable, revocable, worldwide license to access and use the Paytechlab Service for incorporation into any of Your social media, online application, mobile application, website, Smart TV, or any other medium that is approved by Paytechlab (each, an “Project” and together, the “Projects”) for the term specified below.</li>
      <li>
        Paytechlab manages a portfolio, which is updated from time to time, of names, logos, unregistered and registered trademarks, copyrights, and other branding materials in supporting documentation that relate to the Paytechlab Services (the “Licensed Material”). Paytechlab grants You a non-exclusive, non-transferable, revocable, worldwide license to use the Licensed Material, for the sole purposes of promoting the Paytechlab Services during the Term, provided that:

        <ol className='lettered-list'>
          <li>your use of the Licensed Material is subject to Paytechlab’s then current policies and procedures, as notified in writing from time to time;</li>
          <li>you acknowledge and agree that any use of the Licensed Material is solely as licensee from Paytechlab and that any goodwill arising from Your use of the Licensed Material will be for the benefit of Paytechlab;</li>
        </ol>
      </li>
      <li>Paytechlab may limit, expand, or terminate this license with prior written notice at any time.</li>
      <li>You grants to Paytechlab a non-exclusive, non-transferable, revocable, and royalty-free worldwide license to use the names, logos, and unregistered or registered trademarks that relate to You or the Project(s) for the purposes of promoting the availability of the Paytechlab Services for the Project(s). Paytechlab acknowledges and agrees that any use of the trademarks is solely as licensee from You and that any goodwill arising from Paytechlab’s use of the trademarks will be for the benefit of You.</li>

      <h2>Delivery</h2>
      <li>You agree to implement the Paytechlab Service on its Project, and make it available to its user base in order to receive payments revenue.</li>
    </div>
  </div>
);

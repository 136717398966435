import { FormEvent, useCallback, useRef, useState } from 'react';

import ReCAPTCHA from 'react-google-recaptcha';

import { Section } from '../../../../components/common/section';
import { SectionAnchor } from '../../../../models/section-anchor';
import { Button } from '../../../../components/shared/button/button';
import { ReactComponent as DesktopEllipse1 } from './desktop-ellipse1.svg';
import { ReactComponent as DesktopEllipse2 } from './desktop-ellipse2.svg';
import { ReactComponent as MobileEllipse1 } from './mobile-ellipse1.svg';
import { ReactComponent as MobileEllipse2 } from './mobile-ellipse2.svg';
import { GOOGLE_RECAPTCHA_KEY } from '../../../../constants';

import './contact-form-section.scss';

export const ContactFormSection = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [nameError, setNameError] = useState('Field is required');
  const [nameBlurred, setNameBlurred] = useState(false);
  const [emailError, setEmailError] = useState('Field is required');
  const [emailBlurred, setEmailBlurred] = useState(false);
  const [messageError, setMessageError] = useState('Field is required');
  const [captchaError, setCaptchaError] = useState('Field is required');
  const [captchaTouched, setCaptchaTouched] = useState(false);
  const [messageBlurred, setMessageBlurred] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);

  const nameInputRef = useRef<HTMLInputElement>(null);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const messageInputRef = useRef<HTMLTextAreaElement>(null);
  const captchaRef = useRef<ReCAPTCHA>(null);

  const validateName = useCallback(() => {
    if (!nameInputRef.current?.value) {
      setNameError('Field is required');
      return;
    }
    if (nameInputRef.current?.value.length < 2) {
      setNameError('Name is too short');
      return;
    }
    if (nameInputRef.current?.value.length > 256) {
      setNameError('Name is too long! Max size 256 symbols');
      return;
    }
    setNameError('');
    return true;
  }, []);

  const validateEmail = useCallback(() => {
    const regex = /(^$)|^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!emailInputRef.current?.value) {
      setEmailError('Field is required');
      return;
    }
    if (!regex.test(emailInputRef.current?.value)) {
      setEmailError('Email not valid');
      return;
    }
    if (emailInputRef.current?.value.length > 320) {
      setEmailError('Email is too long! Max size 320 symbols');
      return;
    }
    setEmailError('');
    return true;
  }, []);

  const validateMessage = useCallback(() => {
    if (!messageInputRef.current?.value) {
      setMessageError('Field is required');
      return;
    }
    if (messageInputRef.current?.value.length < 5) {
      setMessageError('Message is too short');
      return;
    }
    if (messageInputRef.current?.value.length > 4000) {
      setMessageError('Message is too long! Max size 4000 symbols');
      return;
    }
    setMessageError('');
    return true;
  }, []);

  const validateCaptcha = useCallback(() => {
    if (!captchaRef.current?.getValue()) {
      setCaptchaError('Field is required');
      return;
    }
    setCaptchaError('');
    return true;
  }, []);

  const handleFormSubmit = useCallback((e: FormEvent) => {
    e.preventDefault();
    setErrorOccurred(false);
    setNameBlurred(true);
    setEmailBlurred(true);
    setMessageBlurred(true);
    setCaptchaTouched(true);

    if ([
      validateName(),
      validateEmail(),
      validateMessage(),
      validateCaptcha(),
    ].every((isValidationSuccess) => isValidationSuccess)) {
      const data = {
        name: nameInputRef.current?.value || '',
        email: emailInputRef.current?.value || '',
        message: messageInputRef.current?.value || '',
        recaptcha: captchaRef.current?.getValue(),
      };

      fetch(
        '/api/contact_client',
        { method: 'POST', body: JSON.stringify(data), headers: { 'Content-Type': 'application/json' } },
      )
        .then((resp) => {
          if (resp.status === 200) {
            setFormSubmitted(true);
          } else {
            setErrorOccurred(true);
          }
        })
        .catch(() => {
          setErrorOccurred(true);
        });
    }
  }, [validateCaptcha, validateEmail, validateMessage, validateName]);

  const resizeTextarea = useCallback(() => {
    if (!messageInputRef.current) {
      return;
    }
    messageInputRef.current.style.height = '5px';
    messageInputRef.current.style.height = `${ messageInputRef.current.scrollHeight }px`;
  }, []);

  return (
    <Section anchor={ SectionAnchor.Contacts } className='contact-form-section'>
      <div className='container'>
        {
          formSubmitted
            ? (
              <div className='form-submitted-message'>
                <DesktopEllipse1 className='desktop-ellipse1 desktop-image' />
                <DesktopEllipse2 className='desktop-ellipse2 desktop-image' />
                <MobileEllipse1 className='mobile-ellipse1 mobile-image' />
                <MobileEllipse2 className='mobile-ellipse2 mobile-image' />
                <h2>Thank you for getting in touch!</h2>
                <p>
                  {/* eslint-disable-next-line max-len */}
                  We&apos;ve received your request, a member of our team has been notified and will be contacting you shortly
                </p>
              </div>
            )
            : (
              <>
                <h2 className='section-subheader'>Ready to level up your business?</h2>
                <p className='section-text form-subheader'>
                  {/* eslint-disable-next-line max-len */ }
                  Contact us to explore more about product opportunities, get custom prices or discuss your business
                  requirements and we’ll be in touch as soon as possible
                </p>

                <form onSubmit={ handleFormSubmit } className='form'>
                  <div className='form-container'>
                    <div className='inputs-block'>
                      <div className='input-container'>
                        { nameBlurred && !!nameError && <p className='error-text'>{ nameError }</p> }
                        <input
                          type='text'
                          name='name'
                          ref={ nameInputRef }
                          className={ `input ${ nameBlurred && !!nameError ? 'error' : '' }` }
                          placeholder='Your name'
                          onBlur={ () => {
                            setNameBlurred(true);
                            validateName();
                          } }
                        />
                      </div>

                      <div className='input-container'>
                        { emailBlurred && !!emailError && <p className='error-text'>{ emailError }</p> }
                        <input
                          type='email'
                          name='email'
                          ref={ emailInputRef }
                          className={ `input ${ emailBlurred && !!emailError ? 'error' : '' }` }
                          placeholder='Your email'
                          onBlur={ () => {
                            setEmailBlurred(true);
                            validateEmail();
                          } }
                        />
                      </div>
                    </div>
                    <div className='inputs-block'>
                      <div className='input-container textarea'>
                        { messageBlurred && !!messageError && <p className='error-text'>{ messageError }</p> }
                        <textarea
                          name='message'
                          ref={ messageInputRef }
                          onInput={ resizeTextarea }
                          className={ `input textarea ${ messageBlurred && !!messageError ? 'error' : '' }` }
                          placeholder='Your message'
                          onBlur={ () => {
                            setMessageBlurred(true);
                            validateMessage();
                          } }
                        />
                      </div>

                      { captchaTouched && !!captchaError && <p className='error-text recaptcha'>{ captchaError }</p> }
                      <ReCAPTCHA
                        sitekey={ GOOGLE_RECAPTCHA_KEY }
                        ref={ captchaRef }
                        onChange={ validateCaptcha }
                        onExpired={ validateCaptcha }
                        onError={ validateCaptcha }
                        className={ captchaTouched && !!captchaError ? 'google-recaptha-error' : '' }
                      />
                    </div>
                  </div>

                  { errorOccurred && <p className='error-text'>Something went wrong. Please try again later</p> }
                  <div className='button-holder'>
                    <Button type='submit'>Send Message</Button>
                  </div>
                </form>
              </>
            )
        }
      </div>
    </Section>
  );
};

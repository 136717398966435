import { useCallback, useEffect, useLayoutEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import {
  AnchorScrollerMode,
  debounce,
  getBestAnchorGivenScrollLocation,
  scrollToHash,
} from './anchor-scroller-helpers';

export const useAnchorScroller = () => {
  const navigate = useNavigate();

  const handleWindowScroll = useCallback(() => {
    const segments = document.querySelectorAll('*[data-section]') as NodeListOf<HTMLAnchorElement>;
    const bestId = getBestAnchorGivenScrollLocation(segments);
    navigate(bestId ? `#${ bestId }` : '/', { replace: true });
  }, [navigate]);

  useEffect(() => {
    const debouncedFunction = debounce(() => handleWindowScroll(), 35);
    window.addEventListener('scroll', debouncedFunction);
    return () => {
      window.removeEventListener('scroll', debouncedFunction);
    };
  }, [handleWindowScroll]);

  useLayoutEffect(() => {
    const anchor = window.location.hash.replace('#', '');
    if (anchor) {
      scrollToHash(anchor, AnchorScrollerMode.AUTO);
    }
  }, []);
};

/* eslint max-len: 0 */
export const DataProtectionSection = () => (
  <div className='section data-protection-section'>
    <div className='container'>
      <h5>DATA PROTECTION, SECURITY COMPLIANCE & PRIVACY</h5>
      <h2>Security of Your Account Access</h2>
      <li>You agree to: (I) Not allow anyone else to have or use Your password details and to comply with all reasonable instructions we may issue regarding account access and security. In the event You share Your password details, Paytechlab will not be liable to You for losses or damages; (II) In addition, you may create a Sub-Account, protected by a Username and a Password, to provide limited access to anyone else who You require to periodically consult information of Your Paytechlab Account (III) Keep Your personal details up to date. We may be unable to respond to You if You contact us from an address, telephone number or email account that is not registered with us; and (IV) Take all reasonable steps to protect the security of the personal electronic device through which You access the Paytechlab Services.</li>

      <h2>PCI Compliance</h2>
      <li>If You use Paytechlab Services to accept payment card Transactions, You must comply with the Payment Card Industry Data Security Standards (PCI-DSS) and, if applicable to Your business, the Payment Application Data Security Standards (PA-DSS) (collectively, the “PCI Standards”)</li>
      <li>You will promptly provide us with documentation demonstrating Your compliance with the PCI Standards upon our request. If You elect to store, hold and maintain “Account Data”, as defined by the PCI Standards (including Customer card account number or expiration date), You further agree that You will either maintain a PCI-compliant system or use a compliant service provider to store or transmit such Account Data; further, You agree to never store any “Sensitive Authentication Data”, as defined by the PCI Standards (including CVC or CVV2), data at any time.</li>

      <h2>Data Processing</h2>
      <li>You are the data controller and we are the data processor in relation to Personal Data processed on Your behalf under this Agreement, except that we will be a data controller in relation to Personal Data where we determine the purposes and manner in which the Personal Data is processed (including, for example, in complying with any regulations or laws imposed upon us through Network Rules or Services Providers).</li>
      <li>We will, to the extent that we are a data processor, process Personal Data in accordance with the terms of this Agreement and lawful instructions reasonably given by You to us from time to time, and we will employ appropriate technical and organisational measures to protect such Personal Data. We will not be liable for any claim brought by a data subject arising from any action or omission by us, to the extent that such action or omission resulted from Your instructions</li>

      <h2>Confidentiality</h2>
      <li>"Confidential Information" shall mean (a) any data (including any usage data and compilations thereof) relating to the business of the disclosing party, including product designs, product plans, data, software and technology, financial information, marketing plans, business opportunities, proposed terms, pricing or rate information, discounts, inventions and know-how disclosed to the other party; and/or (b) any other information designated in writing, or identified orally at time of disclosure, by the disclosing party as "confidential" or "proprietary." During and after the term of this Agreement, the receiving party shall not use for any purpose, or disclose to any third party, any Confidential Information of the disclosing party except as specifically permitted herein. The foregoing restriction does not apply to information that: (I) has been independently developed by the receiving party without access to the other party's Confidential Information; (II) has become publicly known through no breach of this Section 4.(D.) by the receiving party; (III) has been rightfully received from a third party authorized to make such disclosure; (IV) has been approved for release in writing by the disclosing party; (V) is required to be disclosed by a competent legal or governmental authority, provided that the receiving party gives the disclosing party prompt written notice of such requirement prior to disclosure and assists in obtaining an order to protect the information from public disclosure; or (VI) is required by our payment partners for select payment processing services such as credit card, bank transfer or other services.</li>
    </div>
  </div>
);
